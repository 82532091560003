import React, { memo, useEffect } from "react";
import { Line } from "react-chartjs-2";
import ZoomResetButton from "../colorsOfWater/elkhead/chartJS/ZoomResetButton";
import {
  annotatedVerticalLinePlugin,
  chartBackgroundPlugin,
} from "../colorsOfWater/elkhead/chartJS/ChartPlugins";
import { Box, Button, Grid } from "@material-ui/core";
import { useMultiChart } from "./useMultiChart";
import styled from "styled-components/macro";

const ButtonContainer = styled(Box)`
  text-align: center;
  margin-top: 16px;
  width: 100%;
`;

const ToggleButton = styled(Button)`
  width: 150px;
`;

const MultiChart = ({ data }) => {
  const {
    gridSize,
    chartRefs,
    handleHover,
    resetZoom,
    toggleGridSize,
    getOptions,
    calculateHeight,
  } = useMultiChart(data);

  useEffect(() => {
    chartRefs.forEach((ref, index) => {
      const chart = ref.current;
      if (chart) {
        chart.options.onHover = (event, elements) =>
          handleHover(event, elements);
        chart.update();
      }
    });
  }, [handleHover, chartRefs]);

  return (
    <>
      <ZoomResetButton resetZoom={resetZoom} />
      <ChartGrid
        data={data}
        gridSize={gridSize}
        chartRefs={chartRefs}
        getOptions={getOptions}
        calculateHeight={calculateHeight}
      />
      <ButtonContainer>
        <ToggleButton
          variant="contained"
          color="primary"
          onClick={toggleGridSize}
        >
          {gridSize === 6 ? "Full Width" : "Split View"}
        </ToggleButton>
      </ButtonContainer>
    </>
  );
};

const ChartGrid = ({
  data,
  gridSize,
  chartRefs,
  getOptions,
  calculateHeight,
}) => (
  <Grid container spacing={gridSize === 6 ? 2 : 0}>
    {chartRefs.map((ref, i) => {
      const dataset = data[i];
      const height = calculateHeight(dataset.isLastChart);

      return (
        <Grid
          item
          xs={gridSize}
          key={i}
          style={{
            height,
          }}
        >
          <Line
            plugins={[chartBackgroundPlugin, annotatedVerticalLinePlugin]}
            ref={ref}
            data={dataset}
            options={getOptions(dataset)}
          />
        </Grid>
      );
    })}
  </Grid>
);

export default memo(MultiChart);

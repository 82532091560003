import React from "react";
import { Divider as MuiDivider, Grid, Typography } from "@material-ui/core";
import Options from "./Options";
import { useColorsOfWaterElkhead } from "./useColorsOfWaterElkhead";
import { Helmet } from "react-helmet-async";
import TimeseriesGraph from "./chartJS/TimeseriesGraph";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const ColorsOfWaterElkhead = React.memo(() => {
  const {
    timeInterval,
    handleTimeInterval,
    dateRange: { startDate, endDate },
    setDateRange,
    handleStartDate,
    handleEndDate,
    error,
    isFetching: isLoading,
    refetch,
    data,
  } = useColorsOfWaterElkhead();

  if (error) {
    return <p>Error fetching current week data analysis: {error.message}</p>;
  }

  return (
    <>
      <Helmet title="Colors of Water - Elkhead" />
      <Typography variant="h3" gutterBottom display="inline">
        Colors of Water - Elkhead
      </Typography>

      <Divider my={6} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TimeseriesGraph data={data} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <Options
            timeInterval={timeInterval}
            handleTimeInterval={handleTimeInterval}
            startDate={startDate}
            setDateRange={setDateRange}
            handleStartDate={handleStartDate}
            endDate={endDate}
            handleEndDate={handleEndDate}
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default ColorsOfWaterElkhead;

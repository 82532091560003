/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Activity,
  Monitor,
  Users,
  Map as MapIcon,
  FileText,
  HelpCircle,
  Settings,
} from "react-feather";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import TimeSeriesComparison from "../pages/dataAccess/timeSeries/TimeSeriesComparison";
import SitesSummaryTable from "../pages/dataAccess/Reports/SitesSummaryTable";
import AdminResources from "../pages/dataAccess/AdminResources";
import YamcoloOperations from "../pages/dashboards/Operations/YamcoloOperations";
import StagecoachOperations from "../pages/dashboards/Operations/StagecoachOperations";
import YamcoloTimeSeries from "../pages/dashboards/TimeSeries/YamcoloTimeSeries";
import {
  Timeline,
  YoutubeSearchedFor,
  DateRange,
  Eco,
  Opacity,
  LocalFlorist,
  Rowing,
  LocalLibrary,
  QueryBuilder,
  Build,
  ColorLens,
  Public,
  CalendarToday,
  Assessment,
} from "@material-ui/icons";
import StagecoachTimeSeries from "../pages/dashboards/TimeSeries/StagecoachTimeSeries";
import TemperatureProfiles from "../pages/dataAccess/temperatureProfiles/TemperatureProfiles";
import AuthGuard from "../components/AuthGuard";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";
import QueryAndDownload from "../pages/dataAccess/queryAndDownload";
import WaterYearComparison from "../pages/dataAccess/waterYearComparisons/WaterYearComparison";
import AlertsManagement from "../pages/dataAccess/systemManagement/AlertsManagement";
import Contacts from "../pages/dataAccess/systemManagement/Contacts";
import Groups from "../pages/dataAccess/systemManagement/Groups";
import GroupsToContacts from "../pages/dataAccess/associations/GroupsToContacts";
import SmsHelp from "../pages/dataAccess/systemManagement/SmsHelp";
import Splash from "../pages/presentation/Splash";
import ToolsCards from "../pages/stakeholders/components/ToolsCards";
import AdditionalResources from "../pages/stakeholders/components/AdditionalResources";
import SteamboatTempFlowForecasts from "../pages/dataAccess/SteamboatTempFlowForecasts";
import ColorsOfWaterElkhead from "../pages/dataAccess/colorsOfWater/elkhead/ColorsOfWaterElkhead";
import ClimateAndFlowsExplorer from "../pages/dataAccess/climateAndFlowsExplorer/ClimateAndFlowsExplorer";
import YearOverYearComparisonsTool from "../pages/dataAccess/yearOverYearComparisonsTool/YearOverYearComparisonsTool";
import TimeSeriesSummaries from "../pages/dataAccess/timeSeriesSummaries/TimeSeriesSummaries";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const landingRoute = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Splash,
  children: null,
};

const homeDashRoute = {
  header: "Dashboards",
  id: "All Tools",
  path: "/dashboard",
  icon: <Build />,
  component: ToolsCards,
  children: null,
};

const watershedHealthRoute = {
  id: "Watershed Health Tools",
  path: "/stakeholders/watershed-health",
  icon: <Eco />,
  component: ToolsCards,
};

const watershedManagementRoute = {
  id: "Watershed Management Tools",
  path: "/stakeholders/watershed-management",
  icon: <Opacity />,
  component: ToolsCards,
};

const agricultureAndLandRoute = {
  id: "Agriculture & Land Tools",
  path: "/stakeholders/agriculture-and-land",
  icon: <LocalFlorist />,
  component: ToolsCards,
};

const recreationRoute = {
  id: "River Recreation Tools",
  path: "/stakeholders/recreation",
  icon: <Rowing />,
  component: ToolsCards,
};

const operationsDashboardRoutes = {
  id: "Operations",
  icon: <Settings />,
  children: [
    {
      path: "/yamcolo-operations-dashboard",
      name: "Yamcolo",
      component: YamcoloOperations,
    },
    {
      path: "/stagecoach-operations-dashboard",
      name: "Stagecoach",
      component: StagecoachOperations,
    },
    {
      path: "/tools/temperature-profiles",
      name: "Temperature Profiles",
      component: TemperatureProfiles,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const timeSeriesDashboardRoutes = {
  id: "Time Series",
  icon: <Timeline />,
  children: [
    {
      path: "/yamcolo-time-series-dashboard",
      name: "Yamcolo",
      component: YamcoloTimeSeries,
    },
    {
      path: "/stagecoach-time-series-dashboard",
      name: "Stagecoach",
      component: StagecoachTimeSeries,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const mapExplorerSidebar = {
  header: "Tools",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: "/tools/map-explorer",
  name: "Map Explore",
  component: PublicMap,
};

const mapExplorerRoute = {
  header: "Tools",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: "/tools/map-explorer/:datacategoryPath?/:latitude?/:longitude?/:zoomLev?",
  name: "Map Explore",
  component: PublicMap,
};

const climateAndFlowsExplorerSidebar = {
  id: "Climate & Flows Explorer",
  icon: <Public />,
  path: "/tools/climate-and-flows-explorer",
  name: "Climate & Flows Explorer",
  component: ClimateAndFlowsExplorer,
};

const climateAndFlowsExplorerRoute = {
  id: "Climate & Flows Explorer",
  icon: <Public />,
  path: "/tools/climate-and-flows-explorer/:datacategoryPath?",
  name: "Climate & Flows Explorer",
  component: ClimateAndFlowsExplorer,
};

const yearOverYearComparisonsToolSidebar = {
  id: "Year Over Year Comparisons Tool",
  icon: <CalendarToday />,
  path: "/tools/year-over-year-comparisons-tool",
  name: "Year Over Year Comparisons Tool",
  component: YearOverYearComparisonsTool,
};

const yearOverYearComparisonsToolRoute = {
  id: "Year Over Year Comparisons Tool",
  icon: <CalendarToday />,
  path: "/tools/year-over-year-comparisons-tool/:datacategoryPath?",
  name: "Year Over Year Comparisons Tool",
  component: YearOverYearComparisonsTool,
};

const timeSeriesSummariesSidebar = {
  id: "Time Series Summaries",
  icon: <Assessment />,
  path: "/tools/time-series-summaries",
  name: "Time Series Summaries",
  component: TimeSeriesSummaries,
};

const timeSeriesSummariesRoute = {
  id: "Time Series Summaries",
  icon: <Assessment />,
  path: "/tools/time-series-summaries/:datacategoryPath?",
  name: "Time Series Summaries",
  component: TimeSeriesSummaries,
};

const timeSeriesComparisonsSidebar = {
  id: "Time Series Comparisons",
  icon: <Activity />,
  path: "/tools/time-series-comparisons",
  name: "Time Series Comparisons",
  component: TimeSeriesComparison,
};

const timeSeriesComparisonsRoute = {
  id: "Time Series Comparisons",
  icon: <Activity />,
  path: "/tools/time-series-comparisons/:datacategoryPath?",
  name: "Time Series Comparisons",
  component: TimeSeriesComparison,
};

const waterYearComparisonsSidebar = {
  id: "Water Year Comparisons",
  icon: <DateRange />,
  path: "/tools/water-year-comparisons",
  name: "Water Year Comparisons",
  component: WaterYearComparison,
};

const waterYearComparisonsRoute = {
  id: "Water Year Comparisons",
  icon: <DateRange />,
  path: "/tools/water-year-comparisons/:datacategoryPath?",
  name: "Water Year Comparisons",
  component: WaterYearComparison,
};

const siteSummaryRoute = {
  id: "Site Summary Table",
  icon: <FileText />,
  path: "/tools/site-summary-table",
  name: "Site Summary Table",
  component: SitesSummaryTable,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const queryAndDownloadSidebar = {
  id: "Query & Download",
  icon: <YoutubeSearchedFor />,
  path: "/tools/query-and-download",
  name: "Query & Download",
  component: QueryAndDownload,
};

const queryAndDownloadRoute = {
  id: "Query & Download",
  icon: <YoutubeSearchedFor />,
  path: "/tools/query-and-download/:datacategoryPath?",
  name: "Query & Download",
  component: QueryAndDownload,
};

const additionalResourcesSidebar = {
  id: "Additional Resources",
  icon: <LocalLibrary />,
  path: "/tools/additional-resources",
  name: "Additional Resources",
  component: AdditionalResources,
};

const additionalResourcesRoute = {
  id: "Additional Resources",
  icon: <LocalLibrary />,
  path: "/tools/additional-resources/:datacategoryPath?",
  name: "Additional Resources",
  component: AdditionalResources,
};

const cbrfcForefastsSidebar = {
  id: "CBRFC Forecasts",
  icon: <QueryBuilder />,
  path: "https://www.cbrfc.noaa.gov/",
  name: "CBRFC Forecasts",
  link: "external",
};

const steamboatTempFlowForecastsRoute = {
  id: "Steamboat Temp/Flow Forecasts",
  icon: <QueryBuilder />,
  path: "/tools/steamboat-temp-flow-forecasts",
  name: "Steamboat Temp/Flow Forecasts",
  component: SteamboatTempFlowForecasts,
};

const colorsOfWaterElkheadRoute = {
  id: "Colors of Water - Elkhead",
  icon: <ColorLens />,
  path: "/tools/colors-of-water-elkhead",
  name: "Colors of Water - Elkhead",
  component: ColorsOfWaterElkhead,
};

const resourcesRoutes = {
  id: "Admin Resources",
  icon: <HelpCircle />,
  path: "/tools/admin-resources",
  name: "Admin Resources",
  component: AdminResources,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const systemManagementRoutes = {
  id: "System Management",
  icon: <Settings />,
  children: [
    {
      path: "/tools/system-management/alerts-management",
      name: "Alerts Management",
      component: AlertsManagement,
    },
    {
      path: "/tools/system-management/contacts",
      name: "Contacts",
      component: Contacts,
    },
    {
      path: "/tools/system-management/groups",
      name: "Groups",
      component: Groups,
    },
    {
      path: "/tools/system-management/associate-contacts-and-groups",
      name: "Associate Groups with Contacts",
      component: GroupsToContacts,
    },
    {
      path: "/tools/system-management/sms-help",
      name: "SMS Help",
      component: SmsHelp,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  homeDashRoute,
  operationsDashboardRoutes,
  timeSeriesDashboardRoutes,
  climateAndFlowsExplorerRoute,
  yearOverYearComparisonsToolRoute,
  timeSeriesSummariesRoute,
  timeSeriesComparisonsRoute,
  waterYearComparisonsRoute,
  siteSummaryRoute,
  queryAndDownloadRoute,
  additionalResourcesRoute,
  steamboatTempFlowForecastsRoute,
  colorsOfWaterElkheadRoute,
  resourcesRoutes,
  accountRoutes,
  systemManagementRoutes,
];

export const stakeholdersLayoutRoutes = [
  watershedHealthRoute,
  watershedManagementRoute,
  agricultureAndLandRoute,
  recreationRoute,
];

export const dashboardMaxContentLayoutRoutes = [mapExplorerRoute];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoute];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  homeDashRoute,
  watershedHealthRoute,
  watershedManagementRoute,
  agricultureAndLandRoute,
  recreationRoute,
  operationsDashboardRoutes,
  timeSeriesDashboardRoutes,
  mapExplorerSidebar,
  climateAndFlowsExplorerSidebar,
  yearOverYearComparisonsToolSidebar,
  timeSeriesSummariesSidebar,
  timeSeriesComparisonsSidebar,
  waterYearComparisonsSidebar,
  siteSummaryRoute,
  queryAndDownloadSidebar,
  additionalResourcesSidebar,
  cbrfcForefastsSidebar,
  steamboatTempFlowForecastsRoute,
  colorsOfWaterElkheadRoute,
  resourcesRoutes,
  systemManagementRoutes,
];

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import Popup from "../../popup";
import useSources from "../useSources";
import useLayers from "../useLayers";
import { coordinatesGeocoder, MapLogger } from "./mapUtils";
import { BASEMAP_STYLES } from "../../constants";
import debounce from "lodash.debounce";
import createTheme from "../../../../theme";
import { ThemeProvider } from "styled-components/macro";
import { useSelector } from "react-redux";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import DragCircleControl from "../../../../components/map/DragCircleControl";
import { RulerControl } from "mapbox-gl-controls";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";
import { handleCopyCoords, updateArea } from "../../../../utils/map";
import ResetZoomControl from "../../../../components/map/ResetZoomControl";
import { isTouchScreenDevice } from "../../../../utils";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import proj4 from "proj4";

// destination projections
const srcProj = "EPSG:4326"; // WGS84 (Geographic coordinate system with latitude and longitude)
const destProj = "EPSG:3857"; // Web Mercator (Meter coordinates)

// transform coordinate projections
function convertLatLngToWebMercator(latitude, longitude) {
  return proj4(srcProj, destProj, [longitude, latitude]);
}

const mapLogger = new MapLogger({
  enabled: process.env.NODE_ENV === "development",
  prefix: "Public Map",
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

/**
 * The `useMap` hook controls all of the Mapbox functionality. It controls
 * everything from rendering the map and popups to filtering layers to styling
 * layers.
 * The hook exposes the map instance in addition to a variety of handlers
 * responsible for applying filters and styles to the map
 * @param {object} ref a React ref for the map container
 * @param {*} mapConfig initial configuration options for the map
 * @param {object} imageCache a cache of images to add to the map
 * see https://docs.mapbox.com/mapbox-gl-js/api/map/
 */
const useMap = (ref, mapConfig, imageCache) => {
  const theme = useSelector((state) => state.themeReducer);
  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [activeBasemap, setActiveBasemap] = useState(BASEMAP_STYLES[0].style);
  const [dataAdded, setDataAdded] = useState(false);
  const [measurementsVisible, setMeasurementsVisible] = useState(false);
  const [virtualBoreCoordinates, setVirtualBoreCoordinates] = useState(null);
  const [virtualBoreVisible, setVirtualBoreVisible] = useState(false);
  const [dataVizGraphModeVisible, setDataVizGraphModeVisible] = useState(true);
  const [lastLocationIdClicked, setLastLocationIdClicked] = useState(null);
  const [graphModeVisible, setGraphModeVisible] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const [dataVizVisible, setDataVizVisible] = useState(false);
  const [dataVizWellNumber, setDataVizWellNumber] = useState(null);
  const [dataVizGraphType, setDataVizGraphType] = useState(null);

  const graphModeVisibleRef = useRef(graphModeVisible);
  useEffect(() => {
    graphModeVisibleRef.current = graphModeVisible;
  }, [graphModeVisible]);

  const [eventsRegistered, setEventsRegistered] = useState(false);
  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const polygonRef = useRef(null);
  const radiusRef = useRef(null);
  const pointRef = useRef(null);
  const lineRef = useRef(null);
  const measurementsContainerRef = useRef(null);

  const handleClearMeasurements = () => {
    draw.deleteAll();
    polygonRef.current.innerHTML = "";
    radiusRef.current.innerHTML = "";
    pointRef.current.innerHTML = "";
    lineRef.current.innerHTML = "";
    setMeasurementsVisible(false);
  };

  // Fetch a list of sources  and layers to add to the map
  const { sources } = useSources();
  const { layers, setLayers } = useLayers();

  const layersRef = useRef(layers);
  useEffect(() => {
    layersRef.current = layers;
  }, [layers]);

  const handleGraphModeFromPoint = (ndx) => {
    // layers.forEach((layer) => {
    //   if (
    //     ["yampa-river-locations-circle", "yampa-river-locations-symbol"].includes(
    //       layer.id
    //     )
    //   ) {
    //     map.setLayoutProperty(
    //       layer?.lreProperties?.name || layer.id,
    //       "visibility",
    //       "visible"
    //     );
    //   } else {
    //     map.setLayoutProperty(
    //       layer?.lreProperties?.name || layer.id,
    //       "visibility",
    //       "none"
    //     );
    //   }
    // });
    setDataVizVisible(false);
    setDataVizGraphModeVisible(true);
    map.fire("closeAllPopups");

    map.setFilter("yampa-river-locations-circle", null);
    map.setFilter("yampa-river-locations-symbol", null);

    setLastLocationIdClicked(ndx);
    setGraphModeVisible(true);
  };

  //adds control features as extended by MapboxDrawGeodesic (draw circle)
  let modes = MapboxDraw.modes;
  modes = MapboxDrawGeodesic.enable(modes);

  const [draw] = useState(
    new MapboxDraw({
      modes,
      controls: {
        polygon: true,
        point: true,
        trash: true,
        line_string: true,
      },
      displayControlsDefault: false,
      userProperties: true,
    })
  );

  useEffect(() => {
    measurementsVisible
      ? (measurementsContainerRef.current.style.display = "block")
      : (measurementsContainerRef.current.style.display = "none");
  }, [measurementsVisible]);

  /**
   * Function responsible for initializing the map
   * Once the map is loaded, store a reference to the map in
   * our application state and update the map status
   */
  const initializeMap = useCallback(() => {
    if (ref?.current && !map?.loaded()) {
      const mapInstance = new mapboxgl.Map({
        container: ref.current,
        ...mapConfig,
      });

      mapInstance?.on("load", () => {
        mapLogger.log("Map loaded");
        setMap(mapInstance);
        //If the mapConfig has flyTo and flyToZoom, fly to that location after map is loaded
        if (mapConfig?.flyTo && mapConfig?.flyToZoom) {
          mapInstance?.flyTo({
            center: mapConfig.flyTo,
            zoom: mapConfig.flyToZoom,
          });
        }
      });
    }
    //MJB removed map from dependency array because it set an endless loop
  }, [ref, mapConfig]); //eslint-disable-line

  //MJB adding some logic to resize the map when the map container ref size changes
  //ResizeObserver watches for changes in bounding box for ref
  //debounce delays the resize function by 100ms
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(ref.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map, ref]);

  /**
   * Function responsible for adding sources and layers to the map
   * There are a number of checks in place to ensure that sources
   * only get added to the map once the map and sources are loaded and
   * to ensure that layers are only added to the map once the layers are
   * loaded and the associated sources are added to the map
   */
  const loadMapData = useCallback(() => {
    const shouldAddData =
      !!map &&
      sources?.length > 0 &&
      layers?.length > 0 &&
      !!Object.values(imageCache).length &&
      !dataAdded;

    if (shouldAddData) {
      const images = Object.entries(imageCache).map(([key, image]) => ({
        url: image.src,
        id: key,
      }));
      Promise.all(
        images.map(
          (img) =>
            new Promise((resolve, reject) => {
              map.loadImage(img.url, function (error, res) {
                if (error) throw error;
                map.addImage(img.id, res);
                resolve();
              });
            })
        )
      ).then(() => {
        sources.forEach((source) => {
          const { id, ...rest } = source;
          const sourceExists = !!map.getSource(id);
          if (!sourceExists) {
            map.addSource(id, rest);
          }
        });

        mapLogger.log("Sources added to map");

        layers
          .sort((a, b) => ((a.drawOrder || 0) > (b.drawOrder || 0) ? -1 : 1))
          .forEach((layer) => {
            const { lreProperties, ...rest } = layer;
            const layerExists = map.getLayer(layer.id);
            if (!layerExists) {
              if (
                layer.type.includes("raster") &&
                map.getLayer("gl-draw-polygon-fill-inactive.cold")
              ) {
                map.addLayer(rest, "gl-draw-polygon-fill-inactive.cold");
              } else map.addLayer(rest);
            }
          });

        mapLogger.log("Layers added to map");

        setDataAdded(true);
      });
    }
  }, [dataAdded, layers, map, sources, imageCache]);

  const addMapControls = useCallback(() => {
    const shouldAddControls = !!map;
    if (shouldAddControls) {
      //top left controls
      map.addControl(new mapboxgl.NavigationControl(), "top-left");
      map.addControl(new mapboxgl.FullscreenControl(), "top-left");
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true,
        }),
        "top-left"
      );
      map.addControl(new ResetZoomControl(), "top-left");

      //top right controls
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: coordinatesGeocoder,
          zoom: 16,
          mapboxgl: mapboxgl,
          reverseGeocode: true,
          placeholder: "Address/Coords Search",
        }),
        "top-right"
      );

      //bottom left controls
      map.addControl(
        new mapboxgl.ScaleControl({ unit: "imperial", maxWidth: 250 }),
        "bottom-left"
      );
      map.addControl(
        new RulerControl({
          units: "feet",
          labelFormat: (n) => `${n.toFixed(2)} ft`,
        }),
        "bottom-left"
      );

      //event listener to run function updateArea during each draw action to handle measurements popup
      const drawActions = ["draw.create", "draw.update", "draw.delete"];
      drawActions.forEach((item) => {
        map.on(item, (event) => {
          const geojson = event.features[0];
          const type = event.type;
          updateArea(
            geojson,
            type,
            polygonRef,
            radiusRef,
            pointRef,
            lineRef,
            measurementsContainerRef,
            draw,
            setMeasurementsVisible
          );
        });
      });

      //bottom right controls
      //draw controls do not work correctly on touch screens
      !isTouchScreenDevice() &&
        map.addControl(draw, "bottom-right") &&
        !isTouchScreenDevice() &&
        map.addControl(new DragCircleControl(draw), "bottom-right");
    }
  }, [map]); // eslint-disable-line

  const getMapBoundsInMercator = () => {
    const bounds = map.getBounds();
    const [west, south] = convertLatLngToWebMercator(
      bounds.getSouth(),
      bounds.getWest()
    );
    const [east, north] = convertLatLngToWebMercator(
      bounds.getNorth(),
      bounds.getEast()
    );
    return [west, south, east, north].join(",");
  };

  const getFeatureInfo = (coords, layer) => {
    const { lng, lat } = coords;
    let url = layer.lreProperties.getRasterFeatureInfoPayload;

    if (layer.id === "land-cover-2021") {
      const bounds = map.getBounds();
      const width = map.getCanvas().width;
      const height = map.getCanvas().height;
      const x = Math.round(
        ((coords.lng - bounds.getWest()) /
          (bounds.getEast() - bounds.getWest())) *
          width
      );
      const y = Math.round(
        ((bounds.getNorth() - coords.lat) /
          (bounds.getNorth() - bounds.getSouth())) *
          height
      );
      const bbox3857 = getMapBoundsInMercator(bounds);
      /* eslint-disable no-template-curly-in-string */
      url = url
        .replace("${x}", x)
        .replace("${y}", y)
        .replace("${width}", width)
        .replace("${height}", height)
        .replace("${bbox3857}", bbox3857);
    } else if (["wetlands", "riparian"].includes(layer.id)) {
      const pointGeometry = `{"x":${lng},"y":${lat},"spatialReference":{"wkid":4326}}`;
      url = url.replace("${pointGeometry}", pointGeometry);
    }
    /* eslint-disable no-template-curly-in-string */

    return fetch(url)
      .then((response) => response.json())
      .then((data) => data.features[0] ?? [])
      .catch((error) => {
        console.error("Error fetching feature info:", error);
        throw error;
      });
  };

  const handleFeatureInfo = async (e, layer) => {
    if (
      layer.layout.visibility !== "visible" ||
      (layer.type === "raster" &&
        !layer?.lreProperties?.getRasterFeatureInfoPayload)
    )
      return null;

    const newFeature = await getFeatureInfo(e.lngLat, layer);
    if (!newFeature || !newFeature[layer.lreProperties.featuresField])
      return null;

    const formattedProperties = Object.fromEntries(
      layer.lreProperties.rasterFeatureInfoProperties.map((prop) => {
        const rawValue =
          newFeature[layer.lreProperties.featuresField][prop.key];
        return [
          prop.value,
          prop.lookup ? prop.lookup[rawValue] || rawValue : rawValue,
        ];
      })
    );

    return {
      ...newFeature,
      properties: formattedProperties,
      layer: layer,
      id: layer.id,
    };
  };

  const addMapEvents = useCallback(() => {
    if (process.env.NODE_ENV === "development") {
      map?.on("zoom", () => {
        setZoomLevel(map?.getZoom());
      });
    }

    const shouldAddClickEvent = map && layers?.length > 0 && dataAdded;
    if (shouldAddClickEvent && !eventsRegistered) {
      //MJB add event listener for all circle and symbol layers
      // pointer on mouseover
      const cursorPointerLayerIds = layers
        .filter(
          (layer) =>
            ["circle", "symbol"].includes(layer.type) &&
            !layer.lreProperties?.popup?.excludePopup
        )
        .map((layer) => layer.id);
      cursorPointerLayerIds.forEach((layerId) => {
        map.on("mouseenter", layerId, () => {
          map.getCanvas().style.cursor = "pointer";

          map.on("mouseleave", layerId, () => {
            map.getCanvas().style.cursor = "";
          });
        });
      });

      map.on("click", async (e) => {
        setVirtualBoreCoordinates({
          lat: e.lngLat.lat,
          lon: e.lngLat.lng,
        });

        const features = map.queryRenderedFeatures(e.point);
        const rasterFeatures = new Set(
          layersRef.current
            .filter(
              (layer) =>
                layer.type === "raster" && layer.layout.visibility === "visible"
            )
            .map((layer) => layer.id)
        );

        try {
          const newRasterFeatures = await Promise.all(
            Array.from(rasterFeatures).map((layerId) =>
              handleFeatureInfo(
                e,
                layersRef.current.find((layer) => layer.id === layerId)
              )
            )
          );

          newRasterFeatures.sort((a, b) =>
            (b.layer.drawOrder || 0) > (a.layer.drawOrder || 0) ? -1 : 1
          );

          features.push(...newRasterFeatures.filter(Boolean));
        } catch (error) {
          console.error("Error processing raster features:", error);
        }

        if (features[0]?.layer?.id === "yampa-river-locations-circle") {
          setLastLocationIdClicked(features[0]?.properties.ndx);
          setDataVizGraphModeVisible(true);
        }

        //Ensure that if the map is zoomed out such that multiple
        //copies of the feature are visible, the popup appears
        //over the copy being pointed to.
        //only for features with the properties.lat/long field (clearwater wells)
        const coordinates = features[0]?.properties?.latitude_dd
          ? [
              features[0].properties.longitude_dd,
              features[0].properties.latitude_dd,
            ]
          : [e.lngLat.lng, e.lngLat.lat];

        //MJB add check for popups so they only appear on our dynamic layers
        const popupLayerIds = layers
          .filter((layer) => !layer?.lreProperties?.popup?.excludePopup)
          .map((layer) => layer.id);

        const myFeatures = features.filter((feature) => {
          return popupLayerIds.includes(feature?.layer?.id);
        });

        if (myFeatures.length > 0) {
          // create popup node
          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    height="300px"
                    layers={layers}
                    features={myFeatures}
                    handleGraphModeFromPoint={handleGraphModeFromPoint}
                    graphModeVisibleRef={graphModeVisibleRef}
                    setDataVizVisible={setDataVizVisible}
                    setDataVizWellNumber={setDataVizWellNumber}
                    setDataVizGraphType={setDataVizGraphType}
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        }
        map.on("closeAllPopups", () => {
          popUpRef.current.remove();
        });
      });
      //Determine which layers have a hover popup from lreProperties
      const layerIdsThatHaveHoverPopup = layers.reduce((acc, layer) => {
        if (layer?.lreProperties?.hasHoverPopup) acc.push(layer.id);
        return acc;
      }, []);
      const hoverPopup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });
      /**
       * Generates hover popup content based on the properties of the provided layer.
       *
       * @param {Object} layerWithHoverPopup - The layer object containing properties related to hover popup data.
       * @param {number} lastReportedFlow - The last reported flow value.
       * @param {number} lastReportedTemperature - The last reported temperature value.
       * @returns {string} - The HTML content for the hover popup.
       */
      const getHoverPopupContent = (
        layerWithHoverPopup,
        lastReportedFlow,
        lastReportedTemperature
      ) => {
        const hasFlowData =
          layerWithHoverPopup?.lreProperties?.hasHoverPopupFlowData === true;
        const hasTempData =
          layerWithHoverPopup?.lreProperties?.hasHoverPopupTempData === true;
        if (hasFlowData && hasTempData) {
          return `<span style="font-size: .9rem">Flow: ${Number(
            lastReportedFlow.toFixed(0)
          ).toLocaleString()} cfs<br />Temp: ${lastReportedTemperature}</span>`;
        } else if (hasFlowData && !hasTempData) {
          return `<span style="font-size: .9rem">Flow: ${Number(
            lastReportedFlow.toFixed(0)
          ).toLocaleString()} cfs</span>`;
        } else if (!hasFlowData && hasTempData) {
          return `<span style="font-size: .9rem">Temp: ${lastReportedTemperature}</span>`;
        } else {
          return "";
        }
      };
      layerIdsThatHaveHoverPopup.forEach((layerId) => {
        map.on("mouseenter", layerId, (e) => {
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = "pointer";
          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          const lastReportedFlow = e.features[0].properties.last_reported_flow;
          const lastReportedTemperature =
            e.features[0].properties.last_reported_water_temperature_formatted;
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          const layerWithHoverPopup = layers.find(
            (layer) => layer.id === layerId
          );
          const popupContent = getHoverPopupContent(
            layerWithHoverPopup,
            lastReportedFlow,
            lastReportedTemperature
          );
          // Populate the popup and set its coordinates based on the feature found.
          hoverPopup.setLngLat(coordinates).setHTML(popupContent).addTo(map);
        });
        map.on("mouseleave", layerId, () => {
          map.getCanvas().style.cursor = "";
          hoverPopup.remove();
        });
      });
      //handles copying coordinates and measurements to the clipboard
      const copyableRefs = [polygonRef, radiusRef, pointRef, lineRef];
      copyableRefs.forEach((ref) => {
        ref.current.addEventListener("click", (e) => {
          handleCopyCoords(e.target.textContent);
        });
      });
      setEventsRegistered(true);
      mapLogger.log("Event handlers attached to map");
      setIsMapLoaded(true);
    } //eslint-disable-next-line
  }, [map, layers, dataAdded, eventsRegistered, theme.currentTheme]);

  /**
   * Handler used to apply user's filter values to the map instance
   * We rely on the `setFilter` method available on the map instance
   * and Mapbox expressions to apply filters to the wells layer
   * This function translates the filter values into valid
   * Mapbox expressions
   * Mapbox expressions are gnarly, powerful black box.
   * See https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
   * The easiest way to get a feel for expressions is to create a new map
   * in Mapbox Studio, add a map layer, apply some filters to the layer and
   * then click on the code icon in the sidebar drawer to inspect the
   * Mapbox expression that is generated for the applied filters
   * (ask Ben Tyler if this doesn't make sense)
   * @param {object} filterValues object representing all of the current
   * filter values
   */
  const updateLayerFilters = (filterValues) => {
    if (!!map) {
      /**
       * Setting to all means that all conditions must be met
       * Equivalent to and in SQL, change to "any" for the or
       * equivalent
       */
      const mapFilterExpression = ["all"];
      Object.values(filterValues).forEach((filter) => {
        if (filter.type === "multi-select") {
          mapFilterExpression.push([
            "match",
            ["get", filter.layerFieldName],
            [...filter.value].length ? [...filter.value] : "",
            true,
            false,
          ]);
        } else if (filter.type === "multi-select-array") {
          const mapFilterExpressionTemp = ["case"];
          if (filter?.value?.length) {
            filter.value.map((item) =>
              mapFilterExpressionTemp.push(
                ["in", item, ["get", filter.layerFieldName]],
                true
              )
            );
          } else {
            mapFilterExpressionTemp.push(
              ["in", "", ["get", filter.layerFieldName]],
              true
            );
          }
          mapFilterExpressionTemp.push(false);
          mapFilterExpression.push(mapFilterExpressionTemp);
        } else if (filter.type === "graphMode") {
          mapFilterExpression.push([
            "match",
            ["get", filter.layerFieldName],
            [...filter.value].length ? [...filter.value] : "",
            true,
            false,
          ]);
        } else if (filter.type === "boolean") {
          //MJB only apply filter if toggle is true
          //MJB no filter applied if toggle is false
          if (filter.value) {
            mapFilterExpression.push([
              "==",
              ["get", filter.layerFieldName],
              filter.value,
            ]);
          }
        }
      });
      map.setFilter("yampa-river-locations-circle", mapFilterExpression);
      map.setFilter("yampa-river-locations-symbol", mapFilterExpression);
      mapLogger.log(
        "Filters updated on the yampa-river-locations-circle layer"
      );
    }
  };

  /**
   * Handler used to update paint styles applied to map layer
   * This is used to support the color Data Points by x control
   * We look through each provided paint style property and apply the
   * style rule to the layer
   * Reference https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setpaintproperty
   * @param {object} layer object representing a map layer
   */
  const updateLayerStyles = useCallback(
    (layer) => {
      if (!!map) {
        Object.entries(layer.paint).forEach(([ruleName, ruleValue]) => {
          map.setPaintProperty(layer.layerId, ruleName, ruleValue);
        });
        setLayers((prevState) => {
          return prevState.map((d) => {
            if (d.id !== layer.layerId) return d;
            return {
              ...d,
              paint: {
                ...d.paint,
                ...layer.paint,
              },
            };
          });
        });
        mapLogger.log(
          "Paint styles updated on the yampa-river-locations-circle layer"
        );
      }
    },
    [map, setLayers]
  );

  /**
   * Handler used to update the visibility property on a layer
   * We employ special logic in this handler to allow for toggling
   * the visibility of grouped layers on and off
   * This allows us to display a single item in the layers list
   * but to control the visibility of multiple map layers at once
   * A common use case would be for something like parcels where
   * you want to display a layer for the parcel outlines and a layer
   * for the parcel fill.
   * This approach allows us to only show one layer in
   * the layer control list but to turn both layers on/off
   * @param {string | number} options.id ID associated with the layer or layer group
   * @param {boolean} options.boolean whether the layer is on/off
   */
  const updateLayerVisibility = useCallback(
    ({ id, visible, radioGroupToggle = false }) => {
      // Get the group key for the given id
      const groupKey = layers.find((layer) => layer.id === id)?.lreProperties
        ?.legendGroup;

      // Get a list of the IDs for the layers that need to have their visibility updated
      const groupedLayerIds = layers
        ?.filter((layer) => {
          const key = layer?.lreProperties?.layerGroup || layer.id;
          return key === id;
        })
        .map(({ id }) => id);

      if (!!map) {
        let layersSetToNone = [];

        // If radioGroupToggle is true, turn off all layers with the same legendGroup but different layerGroup
        if (radioGroupToggle && groupKey) {
          layers.forEach((layer) => {
            if (
              !!map.getLayer(layer.id) &&
              layer?.lreProperties?.legendGroup === groupKey &&
              layer?.lreProperties?.layerGroup !== id
            ) {
              map.setLayoutProperty(layer.id, "visibility", "none");
              layersSetToNone.push(layer.id);
            }
          });
        }

        // Loop through all of the layers and update the visibility
        // for all of the layers associated with the layer toggled
        const updatedLayers = layers.map((layer) => {
          if (!!map.getLayer(layer.id) && groupedLayerIds.includes(layer.id)) {
            const visibleValue = visible ? "visible" : "none";
            map.setLayoutProperty(layer.id, "visibility", visibleValue);

            return {
              ...layer,
              layout: {
                ...layer?.layout,
                visibility: visibleValue,
              },
            };
          }
          return layer;
        });

        setLayers(updatedLayers);

        mapLogger.log(
          `Visibility set to ${
            visible ? "visible" : "none"
          } for the ${id} layer`
        );

        if (layersSetToNone.length > 0) {
          mapLogger.log(
            `Visibility set to none for the following layers due to radioGroupToggle: ${layersSetToNone.join(
              ", "
            )}`
          );
        }
      }
    },
    [layers, map, setLayers]
  );

  const updateLayerOpacity = useCallback(
    ({ id, opacity }) => {
      if (!!map) {
        const updatedLayers = layers.map((layer) => {
          if (!!map.getLayer(id) && layer.id === id) {
            map.setPaintProperty(id, "fill-opacity", opacity);
            return {
              ...layer,
              paint: {
                ...layer?.paint,
                "fill-opacity": opacity,
              },
            };
          }
          return layer;
        });
        setLayers(updatedLayers);
        mapLogger.log(`Opacity set to ${opacity} for the ${id} layer`);
      }
    },
    [layers, map, setLayers]
  );

  const updateBasemap = (style) => {
    map?.setStyle(style.url);

    /**
     * After the map style changes we need to poll it every
     * 100 ms to determine if the new map style has loaded
     * After it is loaded, we add any existing sources and layers
     * back to the map
     */
    const checkStyleLoaded = setInterval(() => {
      const styleLoaded = map?.isStyleLoaded();
      if (styleLoaded) {
        clearInterval(checkStyleLoaded);
        setActiveBasemap(style.style);
        setDataAdded(false);
        loadMapData();
      }
    }, 1000);
  };

  // initialize and render the map
  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  // add all the sources and layers to the map
  useEffect(() => {
    loadMapData();
  }, [loadMapData]);

  // wire up all map related events
  useEffect(() => {
    addMapEvents();
  }, [addMapEvents]);

  // add all map controls
  useEffect(() => {
    addMapControls();
  }, [addMapControls]);

  return {
    activeBasemap,
    basemaps: BASEMAP_STYLES,
    layers,
    map,
    sources,
    zoomLevel,
    updateBasemap,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    measurementsVisible,
    setMeasurementsVisible,
    polygonRef,
    radiusRef,
    pointRef,
    lineRef,
    measurementsContainerRef,
    handleClearMeasurements,
    eventsRegistered,
    virtualBoreCoordinates,
    setVirtualBoreCoordinates,
    virtualBoreVisible,
    setVirtualBoreVisible,
    lastLocationIdClicked,
    setLastLocationIdClicked,
    dataVizGraphModeVisible,
    setDataVizGraphModeVisible,
    setGraphModeVisible,
    graphModeVisible,

    dataVizVisible,
    setDataVizVisible,
    dataVizWellNumber,
    dataVizGraphType,
    isMapLoaded,
  };
};

export { useMap };

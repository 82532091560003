import React from "react";
import styled from "styled-components/macro";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";

const Root = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const SectionContainer = styled(Box)`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 96px 0 0 0;
  padding: 32px;
`;

const SectionTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 6px;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    text-align: center;
  }
`;

export const HorizontalLine = styled("div")`
  height: 3px;
  flex-grow: 1;
  background-color: #8ed3f1;
  margin-left: 32px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 24px auto 32px 25%;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-left: auto;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: none;
  elevation: 0;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 100%;
    position: relative;
    right: 0;
    height: 100%;
  }
`;

const StyledCardActionArea = styled(CardActionArea)`
  &:hover {
    background-color: transparent; /* Remove background color change */
    box-shadow: none; /* Remove box shadow */
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 32px 32px 16px 32px;
  display: flex;
  flex-direction: column;
`;

const CardLogo = styled("img")`
  object-fit: contain;
  height: 200px;
  max-width: 100%;
  margin-bottom: 48px;
`;

const CardDescription = styled(Typography)`
  color: black;
  font-weight: 600;
`;

const Logo = styled("img")`
  height: 150px;
  max-width: 100%;
  object-fit: contain;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: inherit;
    max-height: 120px;
    max-width: 220px;
  }
`;

function Funding() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Root>
      <SectionContainer>
        <Box
          display={isSmallScreen ? "block" : "flex"}
          alignItems="center"
          width="100%"
          mb="32px"
        >
          <SectionTitle color="primary" variant="h4" width={350}>
            Funding Provided By
          </SectionTitle>
          <HorizontalLine />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <StyledCard>
              <StyledCardActionArea
                rel="noreferrer noopener"
                component="a"
                target="_blank"
                href="https://upperyampawater.com/"
              >
                <StyledCardContent>
                  <CardLogo
                    src="/static/img/splash/sponsors/UpperYampaWater-Logo-Footer.png"
                    alt="Upper Yampa Water Conservancy District"
                  />
                  <CardDescription variant="body2" component="p">
                    Stretching across the headwaters of the Yampa River River
                    Basin from the Flattops in South Routt County to the Park
                    Range in North Routt County and west into Moffatt County,
                    our district is dedicated to responsible water management
                    and the protection of our most valuable natural resource.
                  </CardDescription>
                </StyledCardContent>
              </StyledCardActionArea>
              <CardActions style={{ margin: "16px" }}>
                <Button
                  endIcon={<ChevronRight />}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  rel="noreferrer noopener"
                  component="a"
                  target="_blank"
                  href="https://upperyampawater.com/"
                  style={{ width: "115px", justifyContent: "start" }}
                >
                  Visit
                </Button>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <StyledCard>
              <StyledCardActionArea
                rel="noreferrer noopener"
                component="a"
                target="_blank"
                href="https://yampawhitegreen.com/"
              >
                <StyledCardContent>
                  <CardLogo
                    src="/static/img/splash/sponsors/yampa-white-green-basin-roundtable-logo.png"
                    alt="Yampa-White-Green Basin Roundtable"
                  />
                  <CardDescription variant="body2" component="p">
                    The Yampa White Green Basin Roundtable (YWGBRT) process
                    offers local communities the opportunity to have a strong
                    voice in how water will be managed now and in the future.
                    This unique grassroots process does not exist in other
                    states where water planning is more commonly conducted at
                    the state agency level.
                  </CardDescription>
                </StyledCardContent>
              </StyledCardActionArea>
              <CardActions style={{ margin: "16px" }}>
                <Button
                  endIcon={<ChevronRight />}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  rel="noreferrer noopener"
                  component="a"
                  target="_blank"
                  href="https://yampawhitegreen.com/"
                  style={{ width: "115px", justifyContent: "start" }}
                >
                  Visit
                </Button>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
              alignItems: "center",
            }}
          >
            <SectionTitle color="primary" variant="h5" width={350}>
              Additional Support Provided By
            </SectionTitle>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <a
              href="https://coloradowatertrust.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo
                src="/static/img/splash/sponsors/cwt-logo-splash.webp"
                alt="Colorado Water Trust"
              />
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <a
              href="https://friendsoftheyampa.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo
                src="/static/img/splash/sponsors/yampa-logo-splash.gif"
                alt="Friends of the Yampa"
              />
            </a>
          </Grid>
        </Grid>
      </SectionContainer>
    </Root>
  );
}

export default Funding;

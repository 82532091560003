import React from "react";
import {
  Button,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { spacing } from "@material-ui/system";
import Loader from "../../../../components/Loader";
import QuickSetButtons from "./QuickSetButtons";
import { DATE_FORMAT, TIME_INTERVAL_OPTIONS } from "./constants";

const Paper = styled(MuiPaper)(spacing);
const Divider = styled(MuiDivider)(spacing);

const OptionsGridContainer = styled(Grid).attrs({
  container: true,
})`
  justify-content: space-between;
  align-items: center;
`;

const OptionsGridItem = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: "auto",
})`
  display: flex;
  justify-content: center;
`;

const DateTimeFormControl = ({ id, label, value, onChange }) => (
  <FormControl margin="normal" fullWidth style={{ marginLeft: "8px" }}>
    <DateTimePicker
      size="small"
      format={DATE_FORMAT}
      inputVariant="outlined"
      autoOk
      id={id}
      label={label}
      value={value}
      onChange={onChange}
    />
  </FormControl>
);

const SelectControl = React.memo(
  ({ label, id, value, onChange, options, isLoading, multiple = false }) => (
    <FormControl margin="normal" variant="outlined" fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        multiple={multiple}
        value={value}
        onChange={onChange}
        label={label}
      >
        {isLoading ? (
          <MenuItem disabled>
            <Loader />
          </MenuItem>
        ) : (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
);

const Options = React.memo(
  ({
    timeInterval,
    handleTimeInterval,
    startDate,
    setDateRange,
    handleStartDate,
    endDate,
    handleEndDate,
    refetch,
    showTimeIntervalSelect = false,
    showSubmitButton = false,
  }) => {
    const selectControlsConfig = [
      showTimeIntervalSelect && {
        label: "Time Interval",
        id: "timeInterval",
        value: timeInterval,
        onChange: handleTimeInterval,
        options: TIME_INTERVAL_OPTIONS,
      },
    ].filter(Boolean);

    return (
      <Paper p={3}>
        <OptionsGridContainer>
          <Grid container spacing={3}>
            {selectControlsConfig.map((config, index) => (
              <Grid key={index} item xs={12} md={6}>
                <SelectControl {...config} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <OptionsGridItem>
            <QuickSetButtons setDateRange={setDateRange} />
          </OptionsGridItem>
          <OptionsGridItem>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimeFormControl
                id="start-date"
                label="Start Date"
                value={startDate}
                onChange={handleStartDate}
              />
              <DateTimeFormControl
                id="end-date"
                label="End Date"
                value={endDate}
                onChange={handleEndDate}
              />
            </MuiPickersUtilsProvider>
          </OptionsGridItem>
          {showSubmitButton && (
            <OptionsGridItem>
              <Button
                onClick={() => {
                  refetch();
                }}
                type="submit"
                color="secondary"
                variant="contained"
                disabled={!startDate || !endDate}
              >
                Submit
              </Button>
            </OptionsGridItem>
          )}
        </OptionsGridContainer>
      </Paper>
    );
  }
);

export default Options;

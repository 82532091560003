import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "styled-components/macro";
import { QUICKSET_OPTIONS } from "./constants";

const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const QuickSetButton = ({ option, setFilters }) => {
  const handleButtonClick = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: option.calculateStartDate(),
      endDate: new Date(),
    }));
  };

  return (
    <Tooltip title="Quick set dates">
      <StyledButton
        onClick={handleButtonClick}
        type="submit"
        color="primary"
        variant="contained"
        size="small"
        aria-label={option.label}
      >
        {option.label}
      </StyledButton>
    </Tooltip>
  );
};
const QuickSetButtons = ({ setDateRange }) => {
  return QUICKSET_OPTIONS.map((option, i) => (
    <QuickSetButton key={i} option={option} setFilters={setDateRange} />
  ));
};

export default QuickSetButtons;

import React, { useMemo, useRef } from "react";

import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

import {
  annotatedVerticalLinePlugin,
  chartBackgroundPlugin,
} from "./ChartPlugins";
import styled from "styled-components";
import { TIMESERIES_AXIS_DATE_FORMAT } from "../constants";
import ZoomResetButton from "./ZoomResetButton";
import SaveGraphButton from "./SaveGraphButton";
import Loader from "../../../../../components/Loader";
import { Box, Typography } from "@material-ui/core";

const TableWrapper = styled("div")`
  height: 100%;
  overflow-y: auto;
  max-width: 100%;
  width: 100%;
`;

const TimeseriesContainer = styled("div")`
  flex-grow: 1;
  min-height: 600px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TimeseriesLineChartDashboard = React.memo(({ data, isLoading }) => {
  const ref = useRef(null);
  const options = useMemo(() => {
    return {
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "xy",
            modifierKey: "ctrl",
          },
          zoom: {
            mode: "xy",
            drag: {
              enabled: true,
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 1,
              backgroundColor: "rgba(54, 162, 235, 0.3)",
              drawTime: "afterDatasetsDraw",
            },
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
            sort: (a, b) => {
              const aOrder =
                data?.datasets[a.datasetIndex].legendAndTooltipOrder;
              const bOrder =
                data?.datasets[b.datasetIndex].legendAndTooltipOrder;
              return bOrder - aOrder;
            },
          },
        },
        tooltip: {
          itemSort: (a, b) =>
            b.dataset.legendAndTooltipOrder - a.dataset.legendAndTooltipOrder,
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: TIMESERIES_AXIS_DATE_FORMAT,
            },
            tooltipFormat: TIMESERIES_AXIS_DATE_FORMAT,
          },
          grid: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 9,
          },
          stacked: true,
        },
        yL: {
          display: true,
          title: {
            display: true,
            text: "Flow and Releases (cfs)",
          },
          grid: {
            borderDash: [10, 5],
            drawBorder: true,
            color: "#cbcbcb",
          },
          stacked: true,
        },
      },
    };
  }, [data]);

  return (
    <TimeseriesContainer>
      <TableWrapper>
        {isLoading || !data?.datasets?.length ? (
          <Loader />
        ) : !data?.datasets?.length ? (
          <Typography>No Data Available</Typography>
        ) : (
          <Line
            plugins={[chartBackgroundPlugin, annotatedVerticalLinePlugin]}
            ref={ref}
            data={data}
            options={options}
            type="line"
            style={{ cursor: "pointer" }}
          />
        )}
      </TableWrapper>
      <Box display="flex">
        <ZoomResetButton resetZoom={() => ref?.current?.resetZoom()} />
        <SaveGraphButton
          ref={ref}
          title={`15-Minute Data`}
          disabled={isLoading || !data?.datasets?.length}
        />
      </Box>
    </TimeseriesContainer>
  );
});
export default TimeseriesLineChartDashboard;
